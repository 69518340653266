@import '@cmsgov/design-system-support/src/settings/index';

// stylelint-disable selector-no-qualifying-type
select.ds-c-field {
  $select-icon-size: 10px;
  appearance: none;
  background-color: $color-white;
  background-image: url('#{$image-path}/arrow-both.svg');
  background-position: right $spacer-1 center;
  background-repeat: no-repeat;
  background-size: $select-icon-size;
  padding-right: ($select-icon-size * 2) + $spacer-1;

  &[multiple] {
    background-image: none;
  }

  // Removes inner outline on select elements in Firefox.
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}
// stylelint-enable selector-no-qualifying-type

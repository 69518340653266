#home-checklist:first-child {
  font-size: 18px;
  padding-left: 24px;
}

.pilot-content {
  div {
    margin: 25px 0;
  }

  .usa-accordion__content {
    margin: 0 0 25px 0;
  }
}
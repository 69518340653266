/* stylelint-disable selector-class-pattern */
@import '@cmsgov/design-system-support/src/index';

%link {
  color: $color-primary;
  cursor: pointer;
  text-decoration: underline;

  &:visited {
    color: $color-visited;
  }

  &:hover {
    color: $color-primary-darker;
  }

  &:focus,
  &:focus:visited {
    @include focus-text;
  }

  &:active {
    color: $color-primary-darkest;
    outline: 0 none;
  }
}

// Style for links on darker backgrounds that aren't quite dark as inverse (i.e. Alert)
%link-darker {
  color: $color-primary-darker;
  cursor: pointer;
  text-decoration: underline;

  &:visited {
    color: $color-visited;
  }

  &:hover {
    color: $color-primary-darkest;
  }

  &:focus,
  &:focus:visited {
    background-color: $focus-color;
    box-shadow: $focus-shadow-link;
    color: $color-base;
    outline: 3px solid transparent;
    text-decoration: none;
  }

  &:active {
    color: $color-primary-darkest;
    outline: 0 none;
  }
}

%link-inverse {
  color: $color-base-inverse;
  cursor: pointer;
  text-decoration: underline;

  /* stylelint-disable selector-max-specificity */
  &:focus,
  &:focus:visited {
    @include focus-text-inverse;
  }
  /* stylelint-enable selector-max-specificity */

  &:visited,
  &:hover,
  &:active {
    // :visited links can only style color, not opacity. This color styling is equivalent to:
    // color: $color-base-inverse;
    // opacity: 0.8;
    color: $color-muted-inverse;
  }
}

.ds-c-link {
  @extend %link;
}

.ds-c-link--inverse {
  @extend %link-inverse;
}

@if $ds-include-base-html-rulesets {
  // <a> is the only base HTML element in the design system that
  // has a style declaration. In all other cases, styles are applied using a
  // namespaced class name. This selector isn't scoped under .ds-base, since that
  // would cause the selector's specificity to be higher than most other component
  // selectors (i.e. ds-c-button), necessitating overly specific selectors anytime
  // a developer wanted to change an anchor's color property.
  a {
    @extend %link;
  }

  // Change color of links that are descendants of '.ds-base--inverse'
  //
  // Fix TODO: Because this selector's specificity is higher than most components,
  // changing inverse links will require unusually specific selectors
  //
  // Specifically exclude '.ds-c-button' because this is most likely case where
  // a inverse link element will have other styles applied
  .ds-base--inverse a:not(.ds-c-button) {
    @extend %link-inverse;
  }
}

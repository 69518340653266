.hero-btn__container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .sign_up_btn, .sign_up_btn:visited, .sign_up_btn:hover {
    background-color: $color-primary-alt-light;
    border: 0px;
    color: $color-black;
    font-weight: 700;
    margin: 8px 0;
    padding: 3px $spacer-3;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.banner-btn__container {
  color: $color-white;
  font-size: 14px;
  margin: 0 0 45px 0;

  .banner-btn {
    background-color: $color-success;
    border-radius: 5px;
    margin: 0 auto;
    padding: 8px 45px;
  }

  a, a:visited {
    color: $color-white;
    text-decoration: none;
  }

  a:hover {
    border: 5px solid $color-warn-light;
  }

  .coming-soon {
    background-color: $color-gray;
    max-width: 145px;
  }
}
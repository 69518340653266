html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-sans;
}

body {
  font-family: $font-sans;
  margin: 0 auto;
  overflow-x: hidden;
}

body.nav-is-open {
  overflow: hidden;

  @media (min-width: $width-nav) {
    overflow: visible;
  }
}

.ds-base {
  font-family: $font-sans;
}

h1,
h2,
h3 {
  color: $color-primary-darkest;
}

[contentEditable="true"]:focus,
[href]:focus,
[tabindex]:focus,
button:not([disabled]):focus,
iframe:focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus {
  outline: $focus-outline;
  outline-offset: 2px;
}

code,
kbd,
samp {
  font-family: $font-mono;
}

blockquote {
  border-left: 5px solid $color-gray-light;
  color: $color-gray;
  margin-left: 0;
  padding-left: $spacer-2;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ds-c-choice + label a {
  margin-left: 1ch;
}

.token-text {
  word-wrap: break-word;
  width: 400px;
}

.ds-c-field--copy {
  background-color: $color-gray-ultra-light;
  border-width: 1px;
  border-color: $color-gray-lighter;
  max-width: none;
  padding: $spacer-2;
  resize: none;
}

.header-separator {
  background-color: #323a45;
  padding: 8px;
}

#tos {
  background-color: #F6F6F6;
}

.highlight {
  border-radius: 4px;
  background: #f4f4f4;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;

  .k {
    color: #000000;
    font-weight: bold;
  }

  .kc {
    color: #000000;
    font-weight: bold;
  }

  .mf {
    color: #009999;
  }

  .mi {
    color: #009999;
  }

  .nb {
    color: #0086B3;
  }

  .nl {
    color: #990000;
    font-weight: bold;
  }

  .nt {
    color: #000080;
  }

  .nv {
    color: #008080;
  }

  .o {
    color: #000000;
    font-weight: bold;
  }

  .s1 {
    color: #d14;
  }

  .s2 {
    color: #d14;
  }

  .se {
    color: #d14;
  }
}
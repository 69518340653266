.sidenav {
  position: -webkit-sticky;
  position: sticky;
  top: $spacer-5;
  list-style: none;
  margin: -$spacer-7 $spacer-2 0 0;
  padding: $spacer-7 $spacer-2;
  min-width: 250px;

  @media (min-width: $width-md) {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidenav__item {
  font-size: $h4-font-size;
  list-style: none;
  margin-bottom: $spacer-2;

  ul {
    list-style: none;
  }
}

.sidenav__link:visited,
.sidenav__link:link {
  color: #666;
  text-decoration: none;
}

.sidenav__link:hover {
  color: #000;
  border-left: 3px solid blue;
  padding-left: $spacer-1;
}

.subnav {
  padding-left: $spacer-3;
}

#page-with-sidenav {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $layout-breakpoint) {
    flex-direction: row;
  }
}
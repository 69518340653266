@import '@cmsgov/design-system-support/src/settings/index';

.ds-c-skip-nav {
  left: 0;
  padding: $spacer-1 $spacer-2;
  position: absolute;
  top: ($spacer-4 + $base-font-size) * -2;
  z-index: 100;

  &:focus {
    left: 0;
    top: 0;
  }
}
